// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/Users/dpuru/scratch/2019/FEB/target-landing-page-gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-documents-js": () => import("/Users/dpuru/scratch/2019/FEB/target-landing-page-gatsby/src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-index-js": () => import("/Users/dpuru/scratch/2019/FEB/target-landing-page-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("/Users/dpuru/scratch/2019/FEB/target-landing-page-gatsby/src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-software-js": () => import("/Users/dpuru/scratch/2019/FEB/target-landing-page-gatsby/src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */)
}

exports.data = () => import("/Users/dpuru/scratch/2019/FEB/target-landing-page-gatsby/.cache/data.json")

